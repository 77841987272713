<template>
  <div id="ReklamaciaCompoenent"   v-if="authenticated">
    <div id="dash">
      <h2><i class="fa fa-bug" aria-hidden="true"></i> Reklamácia</h2>
      <md-card class="infois sklo backsklo ">
        <p>Našli ste Reklamáciu našich úprav? Neváhajte nám o nej napísať.</p>
        <p>Pred podaním reklamácie si prosím prejdite, či nefunkčnosť nie je spôsobená tretou stranou (hosting a podobne). Prípadne či všetko robíte správne (napríklad ak sa jedná o import XML, tak môže byť zdroj pozmennený a preto nemusí isť funkcia, alebo jej časť).</p>
        <p><strong>Pokiaľ reklamácia nebude uznaná, Užívateľ resp. Objednávateľ si objednáva opravu u Poskytovateľa.</strong></p>
        <div style="padding: 20px">
          <md-field>
            <label >Reklamovaný task</label>
            <md-select class="myclass" v-model="druh" name="druhn" id="druhn" required >
              <md-option v-for="taskya in taskyarr" :key="taskya.id" :value="taskya.id"> {{taskya.nazov}}</md-option>

            </md-select>
          </md-field>

        <md-field style="margin-top: 60px">
          <label>Dôvod reklamácie</label>
          <md-textarea v-model="description" maxlength="1200" required></md-textarea>
          <md-icon>description</md-icon>
        </md-field>

      <md-button class="md-raised md-accent"  @click="pridajReklamaciu()" style="margin: 20px auto;display: block;">Pridaj Reklamáciu</md-button>
    </div>
    </md-card> </div>
    <TermsModal />
  </div>
</template>

<script>
import store from "../store";
import TermsModal from "@/components/TermsModal.vue";
export default {
  name: "ReklamaciaCompoenent",
  components: {
    TermsModal
  },
  data() {
    return {
      authenticated:this.authenticated,
      druh:'',
      taskyarr:'',
      description:'',
      popisReklamacia:'',
      id:'',
      reklamacia:'',
    }
  },
  methods:{
    setAuthenticated(status) {
      this.authenticated = status;
    },
    pridajReklamaciu(){
      var self = this;
      this.id = this.druh;
      this.reklamacia = this.description;

      if(this.druh != '' && this.description != '') {
        this.$http.post('https://sla.giru.sk/inc/AddReklamacia.php',  { id: this.id, reklamacia: this.reklamacia,kto:store.getters.idkojes }).then(function (response) {
          console.log(response.data);
          if(response.data == 'ano'){
            self.$fire({
              title: "Odoslané na reklamáciu!",
              text: "Váš task ste priali na reklamáciu.",
              type: "success",
              timer: 3000
            }).then(r => {
              console.log(r.value);
              self.$router.push({name:'task'})
            });
          }else{
            self.$fire({
              title: "Neprešlo!",
              text: "Chyba pri uzatvorení! Skúste to ešte raz, ak to nepôjde, napíšte nám..",
              type: "error",
              timer: 3000
            })
          }
        });

      }

    },

  },
  beforeCreate() {
    var self = this;
    this.menoje = store.getters.menosje;
    this.idkoje = store.getters.idkojes;
    if(!this.menoje) {
      store.commit('ADD_IDKOS', '');
      store.commit('ADD_MENO', '');
      window.location.href = '/#/';
    }else{
      self.progresak = true;
      this.authenticated = true;

      this.$http.post('https://sla.giru.sk/inc/LoadTaskReklama.php',  { id:this.idkoje }).then(function (response) {
        console.log(response.data);
        self.taskyarr =response.data;

      });

    }
  }
}
</script>

<style scoped>

.infois{
  padding: 20px;
}
h3{
  color: #d91d5e;
}

h2 .fa{
  color: #ee487d;
}


</style>