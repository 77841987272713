<template>
  <div id="TaskComponent"   v-if="authenticated">
    <div id="dash">

      <h2><i class="fa fa-gavel" aria-hidden="true"></i> GDPR / Ochrana osobných údajov</h2>
      <md-card class="infois">
        <p>Nižšie uvádzame informácie o spracúvaní a ochrane osobných údajov v zmysle nariadenia EP a Rady EÚ č. 2016/679 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov) a zákona SR č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov (ďalej len: „zákon o ochrane osobných údajov“).</p>
        <h3>Prevádzkovateľ</h3>
        <p>Spoločnosť GIRU s.r.o., IČO: 47385341, DIČ:2023862236, IČ DPH: SK2023862236  sídlo: Kružlov 295, 086 04 Kružlov (ďalej aj „Prevádzkovateľ“) je prevádzkovateľom informačného systému, v ktorých táto spoločnosť spracúva Vaše osobné údaje ako osobné údaje dotknutej osoby.
        </p>
        <p>Prevádzkovateľ postupuje pri spracovaní osobných údajov v súlade s právnym poriadkom Slovenskej republiky, pričom v maximálnej miere dbá na súkromie dotknutých osôb a na ochranu osobných údajov, ktoré spracúva. </p>

        <h3>Dôvody spracovania osobných údajov</h3>
        <p>Spracúvanie získaných a poskytnutých osobných údajov je nevyhnutnou súčasťou činnosti Prevádzkovateľa.  </p>
        <p>Bez spracúvania osobných údajov by svojim klientom, nemohol poskytnúť svoje služby v požadovanom rozsahu a kvalite.</p>
        <h3>Základ pre spracovanie osobných údajov</h3>
        <p>Súčasná právna úprava umožňuje spracúvať osobné údaje na základe viacerých právnych základov, pričom k spracúvaniu osobných údajov môže dochádzať aj bez súhlasu dotknutej osoby. Sú to osobné údaje patriace do skupiny nevyhnutných.</p>
        <p>Ak neexistuje iný právny základ spracúvania osobných údajov, osobné údaje budú spracúvané iba na základe a v rozsahu súhlasu dotknutej osoby.<p>
        <h3>Poskytovanie údajov tretím stranám</h3>
        <p>Neposkytujeme</p>
        <h3>Spracovávané údaje </h3>
        <p>Email - v tom prípade ak sa jedná o email, ktorý v sebe obsahuje Meno a Priezvisko</p>
        <h3>Zabezpečenie osobných údajov </h3>
        <p>Prevádzkovateľ prijal všetky potrebné opatrenia nielen technického alebo aj personálneho a kontrolného charakteru, aby úroveň ochrany osobných údajov bola čo najvyššia. Server kde sa uchovávajú osobné údaje je u spoločnosti WebSupport s. r. o., IČO: 36 421 928, sídlo: Karadžičova 12, 821 08 Bratislava</p>
        <h3>Práva dotknutej osoby</h3>
        <p>Dotknutá osoba má právo:</p>
<ul><li>požadovať od Prevádzkovateľa prístup k osobným údajom týkajúcim sa dotknutej osoby,</li>
  <li>na opravu, vymazanie, alebo obmedzenie spracúvania osobných údajov,</li>
  <li>namietať proti spracúvaniu osobných údajov,</li>
  <li>na prenosnosť osobných údajov,</li>
  <li>podať sťažnosť alebo návrh na začatie konania dozornému orgánu, ktorým je Úrad na ochranu osobných údajov Slovenskej republiky,</li>
  <li>svoj súhlas so spracovaním osobných údajov kedykoľvek odvolať.</li>
</ul>
        <p>Akékoľvek otázky súvisiace s ochranou osobných údajov môže dotknutá osoba zaslať na email: helpdesk@giru.sk alebo priamo na adresu sídla Prevádzkovateľa.</p>
        <p>Prevádzkovateľ je povinný bez zbytočného odkladu oznámiť dotknutej osobe porušenie ochrany osobných údajov, ak takéto porušenie ochrany osobných údajov môže viesť k vysokému riziku pre práva dotknutej osoby.</p>
        <p>Prevádzkovateľ týmto poskytuje informácie o zodpovednej osobe. Zodpovednou osobou je samotný prevádzkovateľ Spoločnosť GIRU s.r.o., IČO: 47385341, DIČ:2023862236, IČ DPH: SK2023862236  sídlo: Kružlov 295, 086 04 Kružlov.</p>
      </md-card>
    </div>
  </div>
</template>

<script>
import store from "../store";
export default {
name: "GdprComponents",
  data() {
    return {
      authenticated:this.authenticated,
    }
  },
  methods:{
    setAuthenticated(status) {
      this.authenticated = status;
    },
  },
  beforeCreate() {
    var self = this;
    this.menoje = store.getters.menosje;
    this.idkoje = store.getters.idkojes;
    if(!this.menoje) {
      store.commit('ADD_IDKOS', '');
      store.commit('ADD_MENO', '');
      window.location.href = '/#/';
    }else{
      self.progresak = true;
      this.authenticated = true;

    }
  }
}
</script>

<style scoped>

.infois{
  padding: 20px;
}

h3{
  color: #d91d5e;
}

h2 .fa{
  color: #ee487d;
}

</style>