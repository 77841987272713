<template>
    <div id="TaskComponent"   v-if="authenticated">
        <div id="dash">

            <h2><i class="fa fa-bookmark-o"></i> Cenník / Balíčky</h2>

                <md-card class="infois sklo backsklo">

                    <div>
                        <md-table class=" ">
                            <md-table-row>
                                <md-table-head md-numeric>-</md-table-head>
                                <md-table-head>BASIC (každý klient)</md-table-head>
                                <md-table-head>SLA klient (zazmluvnený klient) <i class="fa fa-star" aria-hidden="true" style="color: gold;"></i></md-table-head>
                            </md-table-row>

                            <md-table-row>
                                <md-table-cell>Cena za každú začatú hodinu</md-table-cell>
                                <md-table-cell style="background: #8bc34a;color: white;font-size: 16px;">40€**</md-table-cell>
                                <md-table-cell style="background: #8bc34a;color: white;font-size: 16px;">40€</md-table-cell>
                            </md-table-row>

                            <md-table-row>
                                <md-table-cell>Predplatené hodiny</md-table-cell>
                                <md-table-cell   style="font-size: 16px;">NIE</md-table-cell>
                                <md-table-cell  style="background: #8bc34a;color: white;font-size: 16px;">ÁNO (min. 10 hod)</md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>Počet taskov/úloh</md-table-cell>
                                <md-table-cell  style="background: #8bc34a;color: white;font-size: 16px;">NEOBMEDZENE</md-table-cell>
                                <md-table-cell  style="background: #8bc34a;color: white;font-size: 16px;">NEOBMEDZENE</md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>Počet aktívnych taskov/úloh</md-table-cell>
                                <md-table-cell   style="font-size: 16px;">1</md-table-cell>
                                <md-table-cell  style="background: #8bc34a;color: white;font-size: 16px;">NEOBMEDZENE</md-table-cell>
                            </md-table-row>

                            <md-table-row>
                                <md-table-cell>Riešenie podľa priority Klienta</md-table-cell>
                                <md-table-cell  style="background: #8bc34a;color: white;font-size: 16px;">ÁNO</md-table-cell>
                                <md-table-cell  style="background: #8bc34a;color: white;font-size: 16px;">ÁNO</md-table-cell>
                            </md-table-row>

                            <md-table-row>
                                <md-table-cell>Riešenie podľa druhu Tasku/Úlohy</md-table-cell>
                                <md-table-cell   style="font-size: 16px;">NIE</md-table-cell>
                                <md-table-cell  style="background: #8bc34a;color: white;font-size: 16px;">ÁNO</md-table-cell>
                            </md-table-row>

                            <md-table-row>
                                <md-table-cell>Lehota pre nástup* / Pri poruche</md-table-cell>
                                <md-table-cell   style="font-size: 16px;">Individuálne*</md-table-cell>
                                <md-table-cell  style="background: #8bc34a;color: white;font-size: 16px;">do 24 hodín</md-table-cell>
                            </md-table-row>

                            <md-table-row>
                                <md-table-cell>Lehota pre nástup* / Realizácia Zmien a Rozvojových úloh</md-table-cell>
                                <md-table-cell   style="font-size: 16px;">Individuálne*</md-table-cell>
                                <md-table-cell  style="background: #8bc34a;color: white;font-size: 16px;">do 3 dní</md-table-cell>
                            </md-table-row>

                            <md-table-row>
                                <md-table-cell>Lehota pre nástup* / Update a Upgrade systému – inovácia systému</md-table-cell>
                                <md-table-cell  style="font-size: 16px;">Individuálne*</md-table-cell>
                                <md-table-cell  style="background: #8bc34a;color: white;font-size: 16px;">do 1 mesiaca</md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div> </md-card>
            <p>„Lehota pre nástup“ – znamená časový interval, ktorý uplynie od Termínu nahlásenia do okamžiku začatia riešenia poruchy alebo požiadavky.</p>
            <p>„Individuálne“ – znamená, že Lehota na násup nie je definovaná a záleží na poradí Taskov/Úloh ktoré sú rozpracované od rôznych klientov.</p>
          <p>** - cena sa môže líšiť podľa bodu  <router-link to="/obchodne" replace>4. Obchodných podmienok</router-link></p>
        </div>
      <TermsModal />
    </div>
</template>

<script>
    import store from "../store";
    import TermsModal from "@/components/TermsModal.vue";
    export default {
        name: "CennikComponent.vue",
      components: {
        TermsModal
      },
        data() {
            return {
                authenticated:this.authenticated,
            }
        },
        methods:{
            setAuthenticated(status) {
                this.authenticated = status;
            },
        },
        beforeCreate() {
            var self = this;
            this.menoje = store.getters.menosje;
            this.idkoje = store.getters.idkojes;
            if(!this.menoje) {
                store.commit('ADD_IDKOS', '');
                store.commit('ADD_MENO', '');
                window.location.href = '/#/';
            }else{
                self.progresak = true;
                this.authenticated = true;

            }
        }
    }
</script>

<style scoped>

    .infois{
        padding: 20px;
    }
    .md-table-cell {
      font-size: 16px !important;
      line-height: 18px;
    }
    h2 .fa{
      color: #ee487d;
    }

    .md-table.md-theme-default .md-table-head {
      color: rgb(255 255 255) !important;
    }
    .md-content.md-theme-default {
      background-color: inherit  !important;
      color: rgb(255 255 255)  !important;
    }

    .md-table.md-theme-default .md-table-content, .md-table.md-theme-default .md-table-alternate-header {
      background-color: inherit  !important;
    }

</style>