<script>

    import { Line, mixins } from 'vue-chartjs';
    const { reactiveProp } = mixins;

    export default {
        extends: Line,
        mixins: [reactiveProp],
        data(){
            return{
                options: {
                    type: Object,
                    maintainAspectRatio: false,

                  legend: {
                    labels: {
                      fontColor: "rgb(255,255,255)",
                    }
                  },

              scales: {
                yAxes: [{
                  ticks: {
                    fontColor: "rgb(255,255,255)",
                  }

                }],
                xAxes: [{
                  ticks: {
                    fontColor: "rgb(255,255,255)",

                  }
                }],

              }
                }
            }},
        mounted () {
            this.renderChart(this.chartData,  this.options)
        }

    }

</script>
<style scoped lang="scss" >

</style>