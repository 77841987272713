<template>
    <div id="TaskIdComponent" v-if="authenticated">
        <div id="dash">
            <h2>
                <router-link to="/task" replace
                >
                <md-button class="md-icon-button  md-raised md-primary" style="margin-top: -10px;margin-right: 15px">
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                </md-button>
                </router-link>
                <i class="fa fa-tasks"></i> Task: <span style="color: #d91d5e">{{nazov}}</span>
            <md-button class="md-raised  ot2"   @click="uzavriettask()" v-if="otvorene"><span class="dontmob"><i class="fa fa-check" aria-hidden="true"></i> Uzavrieť task</span></md-button>
            <md-button class="md-raised  ot3"   v-if="uzavrete"><span class="dontmob"><i class="fa fa-lock" aria-hidden="true"></i> U Z A V R E T É</span></md-button></h2>
<div style="clear:both;"></div>
            <div class="md-layout md-gutter md-alignment-center">
                <div class="md-layout-item polie ">
                    <md-card class="md-cardss sklo">
                        <md-progress-bar md-mode="indeterminate" v-if="progresak"></md-progress-bar>
                       <span class="datum"><i class="fa fa-calendar" aria-hidden="true"></i> {{datum}}</span>
                        <div style="clear:both;"></div>
                        {{popis}}</md-card>
                </div>
                <div class="md-layout-item polia poleimg">
                    <md-card class="md-cardss sklo">
                        <img v-for="imga in imgs" :src="'https://sla.giru.sk/img/'+imga.url"  v-bind:key="imga.url" @click="openImg('https://sla.giru.sk/img/'+imga.url)" />
                    </md-card>
                </div>
            </div>


            <div class="koment"><i class="fa fa-comment-o" aria-hidden="true"></i> Komentáre</div>
            <div style="clear:both;position: relative;height: 10px;width: 100%">
                <md-progress-bar md-mode="indeterminate" v-if="progrescm"></md-progress-bar>
            </div>
            <div v-for="komentar in komentare" v-bind:key="komentar.id">
                <div v-bind:class="komentar.id_firma == 0?'komentadmin':'komentfirma'">
                <span><i class="fa fa-user-circle" aria-hidden="true"></i> {{komentar.id_firma == 0?'Admin':'Vy'}} <i class="fa fa-calendar" aria-hidden="true" style="padding-left: 15px"></i> {{komentar.kedy}}</span>
                {{komentar.komentar}}
                    <div class="poleimgs">
                        <img v-for="imss in komentar.img" :src="'https://sla.giru.sk/img/'+imss.url"  v-bind:key="imss.url" @click="openImg('https://sla.giru.sk/img/'+imss.url)" />
                    </div>
                </div>
                <div style="clear:both;"></div>
            </div>



            <hr class="hris" />
            <div style="clear:both;"></div>
            <div class="md-layout md-gutter md-alignment-center" style="padding: 10px 0;border-radius: 10px; margin: 0px;"  v-if="otvorene">
                <div class="md-layout-item polie" style="padding-right: 10px;padding-left: 10px;">
                    <md-card class="md-cardsps sklo">
                        <md-field style="margin: 0px;" >
                            <label>Komentovať</label>
                            <md-textarea v-model="comment" maxlength="1200" required style="height: 195px;width: 100%;font-size: 13px;"></md-textarea>
                            <md-icon>description</md-icon>
                        </md-field>
                    </md-card>
                </div>
                <div class="md-layout-item polia " style="padding-right: 10px;padding-left: 10px;position: relative">
                    <md-card class="md-cardsps sklo" style="overflow: hidden">
                        <VueFileAgent
                                style="    display: block;
    position: absolute;
    top: 15px;
    left: 15px;
    bottom: 15px;
    right: 15px;"
                                ref="vueFileAgent"
                                :theme="'list'"
                                :multiple="true"
                                :deletable="true"
                                :meta="true"
                                :accept="'image/*'"
                                :maxSize="'10MB'"
                                :maxFiles="14"
                                :helpText="'Pridajte nám prílohu - len IMG (jpg, png, jpeg, gif).'"
                                :errorText="{
      type: 'Chybná prípona súboru. Len IMG (jpg, png, jpeg, gif)',
      size: 'Maximalne 10MB subor',
    }"
                                :thumbnailSize="1200"
                                @select="filesSelected($event)"
                                @beforedelete="onBeforeDelete($event)"
                                @delete="fileDeleted($event)"
                                v-model="fileRecords"
                        ></VueFileAgent>

                    </md-card>
                    <md-button class="md-icon-button md-raised md-primary uloyto" @click="ulozComent()">
                        <i class="fa fa-floppy-o" aria-hidden="true"></i>
                    </md-button>
                </div>
            </div>
            <div style="clear:both; margin: 50px;display: block"></div>
        </div>

    <md-dialog :md-active.sync="imageDialog">
        <img v-bind:src="diaImg">
    </md-dialog>
    </div>
</template>

<script>
    import store from "../store";
    export default {
        name: "TaskIdComponent.vue",
        data() {
            return {
                authenticated:this.authenticated,
                nazov:'',
                popis:'',
                datum:'',
                diaImg:'',
                comment:'',
                imgs:[],
                komentare:[],
                imageDialog: false,
                progresak: true,
                progrescm: true,
                otvorene: false,
                uzavrete: false,
                fileRecords: [],
                fileRecordsForUpload: [],
            }

        },
        methods:{

            setAuthenticated(status) {
                this.authenticated = status;

            },
            ulozComent(){
                var self = this;
                this.popisTasku = this.comment;
                if(this.description != '') {
                    this.$http.post('https://sla.giru.sk/inc/AddComment.php',  {idtask: this.$route.params.id,popisTasku: this.popisTasku,foto:this.fileRecordsForUpload,kto:store.getters.idkojes,email:store.getters.emailje,nazov:store.getters.menosje }).then(function (response) {
                        console.log(response.data);
                        self.$fire({
                            title: "Komentované!",
                            text: "Váš komentár sa pridal.",
                            type: "success",
                            timer: 3000
                        }).then(r => {
                            console.log(r.value);
                            self.comment='';
                            self.$refs.vueFileAgent.deleteFileRecord();
                            self.loadComenty();
                        });

                    });

                }
            },
            loadComenty(){
                var self = this;
                console.log('komentare');
                self.progrescm=true;
                var ideckopod =this.$route.params.id;
                /* komentare */
                this.$http.post('https://sla.giru.sk/inc/LoadCommentOne.php',  { id:ideckopod}).then(function (response) {
                    self.progrescm=false;
                    console.log(response.data);
                    self.komentare = response.data[0];
                });
            },
            uzavriettask(){
                var self = this;
                var ideckopod =this.$route.params.id;
                this.$http.post('https://sla.giru.sk/inc/UzavrietOne.php',  { id:ideckopod}).then(function (response) {
                    console.log(response.data);
                    if(response.data == 'ano'){
                        self.$fire({
                            title: "Uzatvorené!",
                            text: "Váš task ste uzavreli.",
                            type: "success",
                            timer: 3000
                        }).then(r => {
                            console.log(r.value);
                            self.$router.push({name:'task'})
                        });
                    }else{
                        self.$fire({
                            title: "Neuzatvorené!",
                            text: "Chyba pri uzatvorení! Skúste to ešte raz, ak to nepôjde, napíšte nám..",
                            type: "error",
                            timer: 3000
                        })
                    }
                })
            },
            openImg(cesta){
                this.imageDialog = true;
                this.diaImg = cesta;
            },

            filesSelected: function (fileRecordsNewlySelected) {
                var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
                this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            },
            onBeforeDelete: function (fileRecord) {
                var i = this.fileRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    this.fileRecordsForUpload.splice(i, 1);
                } else {
                    if (confirm('Naozaj ho chcete vymazať?')) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                }
            },
            fileDeleted: function (fileRecord) {
                var i = this.fileRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    this.fileRecordsForUpload.splice(i, 1);
                } else {
                    this.deleteUploadedFile(fileRecord);
                }
            },



        },
        beforeCreate() {

            console.log("beforeCreate  TID");
            this.menoje = store.getters.menosje;
            this.idkoje = store.getters.idkojes;

            if(!this.menoje) {
                store.commit('ADD_IDKOS', '');
                store.commit('ADD_MENO', '');
                window.location.href = '/#/';
            }else{
                var self = this;
                this.progresak = true;
                this.progrescm = true;
                this.authenticated = true;
                var ideckopod =this.$route.params.id;
                this.idkoje = store.getters.idkojes;
                this.$http.post('https://sla.giru.sk/inc/LoadTaskOne.php',  { id:ideckopod,user:this.idkoje}).then(function (response) {
                    console.log(response.data);
                    self.nazov = response.data[0]['nazov'];
                    self.popis = response.data[0]['popis'];
                    self.datum = response.data[0]['kedy'];
                    self.imgs = response.data[1]['img'];

                    self.progresak = false;
                    if(response.data[0]['ukoncne'] == 0){
                        self.otvorene = true;
                    }
                    if(response.data[0]['ukoncne'] == 1){
                        self.uzavrete = true;
                    }

                });

                /* komentare */
                this.$http.post('https://sla.giru.sk/inc/LoadCommentOne.php',  { id:ideckopod}).then(function (response) {
                    self.progrescm=false;
console.log(response.data);
                    self.komentare = response.data[0];

                });

            }

        },
    }


</script>

<style scoped>

    .polia{
        width: 30%;
        flex: auto;
    }
    .polie{
        width: 70%;
        flex: auto;
    }

    @media screen and (max-width: 600px) {
        .polia,.polie{
            width: 100%;
            flex: auto;
            margin-bottom: 15px;
        }
        .md-cardss{
            min-height: auto !important;
        }
        .komentfirma,.komentadmin{
            width: 100%  !important;
        }
    }
    @media screen and  (min-width: 600px) and (max-width: 1200px) {
        .polia,.polie{
            width: 50%;
            flex: auto;
        }
    }


        .md-cardss{
            min-height: 350px;
            padding: 15px;
            overflow-y: auto;
        }
    .md-cardsps{
            min-height: 250px;
            padding: 15px;
            overflow-y: auto;
        background-color: white;
        }
    .poleimg img{
        width: 33%;
        cursor: zoom-in;
        transition: transform .2s;
    }
    .poleimg img:hover{
        transform: scale(1.05);
        box-shadow: 5px 5px 15px #a7a7a7;
        border-radius: 5px;
        z-index: 5;
    }
    .poleimgs img{
      width: 48%;
      margin: 1%;
        cursor: zoom-in;
        transition: transform .2s;
    }
    .poleimgs img:hover{
        transform: scale(1.05);
        box-shadow: 5px 5px 15px #a7a7a7;
        border-radius: 5px;
        z-index: 5;
    }
    .ot2{
        background-color: #19a219 !important;
        color: white !important;
        float: right !important;
        margin-top: -4px !important;
        margin-right: 0px !important;
    }
    .ot3{
        background-color: #404040 !important;
        color: white !important;
        float: right !important;
        margin-top: -4px !important;
        margin-right: 0px !important;
    }
    .datum{
        color: #9e9e9e;
        margin-bottom: 10px;
        display: block;
    }
    .md-progress-bar {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 5px;
        width: 100%;
    }
    .koment{
        text-align: center;
        font-size: 16px;
        margin: 10px 0;
        color: #8BC34A;
    }
    .uloyto{
        position: absolute;
        bottom: -16px;
        left: -23px;
        background-color: #d91d5e;
    }
    .hris{
            border-top: 1px dotted #9E9E9E;
        margin-top: 50px;
    }
    .komentfirma{
        width: 50%;
        background-color: #4c515e;
        border-radius: 5px;
        padding: 15px;
        margin-bottom: 15px;
        position: relative;
      color: #ffffff;
    }

    .komentfirma span{
        display: block;
        padding-bottom: 9px;
        color: #ffffff;
        font-size: 12px;
    }
    .komentfirma::before {
        content: '';
        position: absolute;
        visibility: visible;
        top: 0px;
        left: -10px;
        border: 10px solid transparent;
        border-top: 10px solid #4c515e;
    }



    .komentadmin{
        width: 50%;
        float: right;
        background-color: #d91c5e;
        border: 1px solid #d91c5e;
        color:white;
        border-radius: 5px;
        padding: 15px;
        margin-bottom: 15px;
        position: relative;
    }

    .komentadmin span{
        display: block;
        padding-bottom: 9px;
        color: #ffffff;
        font-size: 12px;
    }
    .komentadmin::before {
        content: '';
        position: absolute;
        visibility: visible;
        top: -1px;
        right: -10px;
        border: 10px solid transparent;
        border-top: 10px solid #d91c5e;
    }


    .fa-tasks{
      color: #ee487d;
    }

</style>