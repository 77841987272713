<template>
  <div id="ReportComponent"   v-if="authenticated">
    <div id="dash">
      <h2><i class="fa fa-file-pdf-o"></i> Report</h2>
        <div class="nemajupredplatene"><i class="fa fa-info" aria-hidden="true"></i>
            <strong>Výpis úloh je nepoužiteľný na právne úkony a má len informačný charakter.</strong>
            <p><em>(táto funkcia je v Beta testovaní v prípade nezrovanalostí nás prosím kontaktujte)</em></p>
        </div>
      <md-card class="padd  sklo backsklo">
        <md-progress-bar md-mode="indeterminate" v-if="progresak"></md-progress-bar>
        <div style="padding: 15px">
          <div style="max-width: 300px;float: right;">

            <md-button class="md-icon-button md-raised md-primary" style="float: right;margin: 5px;position:relative" @click="print" ><md-tooltip md-direction="top">Nový task</md-tooltip>
              <i class="fa fa-print" style="margin-top: 2px;"></i>
            </md-button>
          </div>


            <p>Výber dátumov sa vyberá podľa dátumu ukončenia úlohy.</p>
        <div style="clear: both"></div>
        <md-datepicker v-model="selectedDate" style="max-width: 180px;margin: 10px;float: left;" md-immediately>
          <label>Od</label>
        </md-datepicker>  <md-datepicker md-immediately v-model="selectedDate2" style="max-width: 180px;margin: 10px;float: left;">
          <label>Do</label>
        </md-datepicker>
        <md-button class="md-primary md-raised" @click="loadReport" style="margin-top: 23px;
    margin-left: 15px;">Report</md-button>
        <div style="clear: both"></div>

          <md-table v-model="strankySu" md-sort="nazov" md-sort-order="asc"  id="printMe">

            <md-table-row slot="md-table-row" slot-scope="{ item }"   >

              <md-table-cell md-label="Názov" md-sort-by="nazov">
                <span class="noaz" @click="lockTask(item.id)">
                  <i class="fa fa-tag" aria-hidden="true"></i> {{item.nazov}}
                </span>
              </md-table-cell>
              <md-table-cell md-label="Zadanie tasku" md-sort-by="kedy">
              {{item.kedy}}
              </md-table-cell>
              <md-table-cell md-label="Uzavretie tasku" md-sort-by="ukoncne">
              {{item.ukoncne}}
              </md-table-cell>
              <md-table-cell md-label="Čas na spracovanie (H:m:s)" md-sort-by="cas">
              {{item.cas}}
              </md-table-cell>


            </md-table-row>
          </md-table>
        </div>
        </md-card>
    </div>
    <TermsModal />
  </div>
</template>

<script>

import store from "@/store";
import TermsModal from "@/components/TermsModal.vue";

export default {
  name: "ReportComponent.vue",
  components: {
    TermsModal
  },
  data() {
    return {
      authenticated:this.authenticated,
      selectedDate: null,
      selectedDate2: null,
      progresak:false,
      strankySu:[],
      search: null,
      defaultSearch:[],
}
},
  methods:{
    setAuthenticated(status) {
      this.authenticated = status;

    },
    lockTask(id){
      this.$router.push({path:'/task/'+id})

    },
    print () {
      // Pass the element id here
      this.$htmlToPaper('printMe');
    },
    loadReport(){
      var self = this;
      self.progresak = true;
      this.$http.post('https://sla.giru.sk/inc/AddReport.php',  { odReport: self.selectedDate,doReport: self.selectedDate2,kto:store.getters.idkojes}).then(function (response) {
        console.log(response.data);
        self.progresak = false;
        self.strankySu =response.data;
      });
        },
    },
  beforeCreate() {
    var self = this;

    console.log("beforeCreate  T");
    this.menoje = store.getters.menosje;
    this.idkoje = store.getters.idkojes;

    if(!this.menoje) {
      store.commit('ADD_IDKOS', '');
      store.commit('ADD_MENO', '');
      window.location.href = '/#/';
    }else{
      self.progresak = true;
      this.authenticated = true;

    }

  },
}

</script>

<style scoped lang="scss">

    .nemajupredplatene{
        border: 2px solid #03A9F4;
        margin-bottom: 25px;
        display: inline-table;
        width: 100%;

    i{
        color: white;
        background-color: #03a9f4;
        padding: 25px 25px;
        margin-left: -1px;
        margin-bottom: -1px;
        font-size: 30px;
        float: left;
        margin-right: 25px;
    }
    strong{
        letter-spacing: 2px;
        margin-top: 10px;
        display: inline-block;
        color: #03a9f4;
    }
    p{

        margin-top: 5px;
        margin-bottom: 5px;

        color: #03a9f4;
    }
    }

.md-card{
  padding-top: 5px !important;
  border-radius: 10px;
  overflow: hidden;
}
.md-progress-bar {
  position: absolute;
  top: 0px;
  height: 5px;
  width: 100%;
}
.noaz{
  cursor: pointer;
}
.noaz:hover{
  color: #ee487d;
}
th{
  padding: 5px;
}
    h2 .fa{
      color: #ee487d;
    }

</style>