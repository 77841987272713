<template>
    <div id="DashComponent"  v-if="authenticated">
        <div id="dash">

            <h2><i class="fa fa-tachometer"></i> Dashboard</h2>
            <div style="clear: both;"></div>
            <div class="nemajupredplatene" v-if="ukayto"><i class="fa fa-info" aria-hidden="true"></i>
                <strong>Balíček “Basic“</strong>
                <p>Využite nás naplno a prejdite na Balíček “SLA klient“ (SLA zmluva). Pre zmenu balíčka nás neváhajte kontaktovať. <router-link to="/obchodne" replace>Obchodné podmienky</router-link> a <router-link to="/cennik" replace>Cenník</router-link> </p>
            </div>
            <div style="clear: both;"></div>
<div class="md-layout md-gutter md-alignment-center">
    <RotatingDiv class="md-layout-item stvorka ">
        <md-card class="info sklo backsklo">
            <md-progress-bar md-mode="indeterminate" v-if="progresak"></md-progress-bar>
            <i class="fa fa-tags" aria-hidden="true" style="color: #ffffff;"></i>
            <span  style="color: #ffffff;">{{vsetktask}}</span>
            Všetkých taskov
        </md-card>
    </RotatingDiv>
    <RotatingDiv class="md-layout-item stvorka ">
        <md-card class="info sklo backsklo">
            <md-progress-bar md-mode="indeterminate" v-if="progresak"></md-progress-bar>
            <i class="fa fa-puzzle-piece" aria-hidden="true" style="color: #4CAF50;"></i>
            <span  style="color: #4CAF50;">{{otvtes}}</span>
            Otvorených taskov
        </md-card>
    </RotatingDiv>
    <RotatingDiv class="md-layout-item stvorka ">
        <md-card class="info sklo backsklo">
            <md-progress-bar md-mode="indeterminate" v-if="progresak"></md-progress-bar>
            <i class="fa fa-clock-o" aria-hidden="true" style="color: #2196F3;"></i>
            <span  style="color: #2196F3;">{{predploa}} hod. / {{obdobie}}</span>
            Predplatených hodín
        </md-card>
    </RotatingDiv>
    <RotatingDiv class="md-layout-item stvorka ">
        <md-card class="info sklo" style="background-color: #E91E63  !important; color: white">
            <md-progress-bar md-mode="indeterminate" v-if="progresak"></md-progress-bar>
            <i class="fa fa-heart-o" aria-hidden="true" style="color: #ffffff;"></i>
            <span  style="color: #ffffff;">{{casma}}</span>
            Čas tento {{obdobie}}
        </md-card>
    </RotatingDiv>
    <RotatingDiv class="md-layout-item stvorka">
        <md-card class="info sklo" style="background-color: #4caf50  !important; color: white">
            <md-progress-bar md-mode="indeterminate" v-if="progresak"></md-progress-bar>
            <i class="fa fa-euro" aria-hidden="true" style="color: #ffffff;"></i>
            <span  style="color: #ffffff;">{{casmacena}} €</span>
            Predpokladaná cena
        </md-card>
    </RotatingDiv>


    <div class="md-layout-item polka">
        <md-card class="infois sklo backsklo">
            <line-chart :chart-data="datacollection"  style="position: relative; display: block; height: 370px "></line-chart>
        </md-card>
    </div>
        <div class="md-layout-item polka">
            <md-card  class="infois sklo" style="background-color: #feffc3  !important">
                <textarea v-model="poznamka" md-autogrow style="width: 100%;height: 100% !important;background-color: inherit;border: 0px;overflow-y: auto;font-size: 18px;line-height: 25px;"></textarea>
            </md-card>
            <md-button class="md-icon-button md-raised buttonis" @click="ulozpoznamku()">
                <i class="fa fa-floppy-o" aria-hidden="true" style="color: #ffffff;"></i>
            </md-button>
        </div>


</div>
        </div>

  <TermsModal/>
    </div>
</template>

<script>
    import LineChart from './graf.vue';
    import store from "../store";
    import RotatingDiv from "@/components/RotatingDiv.vue";
    import TermsModal from "@/components/TermsModal.vue";

    export default {

        name: "DashComponent",
        components: {
            LineChart,
          RotatingDiv,
          TermsModal
        },

        data() {
            return {
                ukayto:false,
                progresak:false,
                vsetktask:0,
                otvtes:0,
                predploa:0,
                obdobie:0,
                casma:0,
                casmacena:0,
                poznamka:'',
                authenticated:this.authenticated,
                datacollection: null,
                datagraf:[]
            }

        },
        mounted () {
            this.fillData();
        },

        methods:{

            setAuthenticated(status) {
                console.log(status+'ddd');
                this.authenticated = status;

            },
            ulozpoznamku(){
                var self = this;
                this.idkoje = store.getters.idkojes;
                this.$http.post('https://sla.giru.sk/inc/AddDaschpozn.php',  { id:this.idkoje,poznamka:this.poznamka}).then(function (response) {
                    console.log(response.data);
                    self.$fire({
                        title: "Uložené!",
                        text: "Vaša poznámka sa uložila.",
                        type: "success",
                        timer: 3000
                    })
                })
            },
            fillData () {
                var self = this;
                this.idkoje = store.getters.idkojes;
                this.$http.post('https://sla.giru.sk/inc/LoadDataGraf.php',  { id:this.idkoje}).then(function (response) {
                    console.log(response.data);
               //     self.datagraf = response.data;


                    self.datacollection = {
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
                    datasets: [
                        {
                            label: 'Tasky',
                            borderColor: '#d91d5e',
                          backgroundColor: "rgba(222,40,103,0.52)",
                          hoverBackgroundColor: "rgb(175,1,120)",
                            data: response.data

                        }
                    ]
                }
                });
            },
            getRandomInt () {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            }

        },

        beforeCreate() {
            var self = this;
            console.log("beforeCreate  D");
            this.menoje = store.getters.menosje;
            this.idkoje = store.getters.idkojes;
            if(!this.menoje) {
                store.commit('ADD_IDKOS', '');
                store.commit('ADD_MENO', '');
                window.location.href = '/#/';
            }else{

                self.progresak = true;
                this.authenticated = true;
                this.$http.post('https://sla.giru.sk/inc/LoadDaschInfo.php',  { id:this.idkoje}).then(function (response) {
                    self.progresak = false;
                    console.log(response.data);
                    self.vsetktask = response.data[0]['celkovo'];
                    self.otvtes = response.data[0]['treba'];
                        self.predploa = response.data[0]['predplatene'];
                        if(self.predploa == 0){
                            self.ukayto = true;
                        }
                            self.casma = response.data[0]['mesiac'];
                            self.casmacena = response.data[0]['mesiaccena'];
                            self.poznamka = response.data[0]['poznamka'];
                            self.obdobie = response.data[0]['obdobie'];

                });

            }

        },
    }

</script>

<style scoped lang="scss" >

    .nemajupredplatene{
        border: 2px solid #03A9F4;
        margin-bottom: 25px;
        display: inline-table;
        width: 100%;

        i{
            color: white;
            background-color: #03a9f4;
            padding: 25px 25px;
            margin-left: -1px;
            margin-bottom: -1px;
            font-size: 30px;
            float: left;
            margin-right: 25px;
        }
        strong{
            letter-spacing: 2px;
            margin-top: 10px;
            display: inline-block;
            color: #03a9f4;
        }
        p{

            margin-top: 5px;
            margin-bottom: 5px;

            color: #03a9f4;
        }
    }

    .md-progress-bar {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 5px;
        width: 100%;
        border-radius: 5px 5px 0 0;
    }
.polka{
    width: 50%;
    flex: inherit;
    margin-top: 25px;
    position: relative;
}
.buttonis{
    position: absolute;
    right: 22px;
    bottom: 10px;
    background-color: #9C27B0 !important;
    color: white;
}

.stvorka{
    width: 20%;
    flex: inherit;
}
    .info{
        width: 100%;
        padding: 25px;
        i{
            float: left;
            font-size: 50px;
            margin-right: 10px;
            opacity: 0.5;
        }
        span{
            display: block;
            font-size: 18px;
            font-weight: bold;
        }
    }
    .infois{
        padding: 20px;
        height: 400px;
    }

@media screen and (max-width: 600px) {
    .stvorka{
        width: 100%;
        margin-bottom: 10px;
    }
    .polka{
        width: 100%;
    }
}
@media screen and  (min-width: 600px) and (max-width: 1200px) {
    .stvorka{
        width: 50%;
    }
    .polka{
        width: 100%;
    }
}
    textarea:focus {
        outline: none !important;
    }
    h2 .fa{
      color: #9C27B0;
    }
</style>