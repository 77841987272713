<template>
    <div id="TaskComponent"   v-if="authenticated">

        <md-drawer class="md-right sklo" :md-active.sync="showSidepanel" style="z-index: 99;margin-top: -44px; padding-top: 15px;width: 50%;min-width: 300px;">
            <span class="md-transparent" md-elevation="0">
                <span class="md-title">Pridaj task </span>
            </span>
            <div style="padding: 20px">
                <md-field>
                    <label for="druhn">Druh</label>
                    <md-select v-model="druh" name="druhn" id="druhn">
                        <md-option value="1"><i class="fa fa-comments-o" aria-hidden="true" style="width: 25px;"></i> Hlásenie požiadavky - Riešenie podľa poradia. 40€ bez DPH / Hod</md-option>
                        <md-option value="2"><i class="fa fa-bug" aria-hidden="true" style="width: 25px;color: darkred"></i> ASAP - Hlásenie poruchy / Error. Riešenie do 48h. 40€ bez DPH / Hod</md-option>
                        <md-option value="0"><i class="fa fa-clock-o" aria-hidden="true" style="width: 25px;color: #8bc34a"></i> ASAP - Hlásenie požiadavky. Riešenie do 48h. 60€ bez DPH / Hod</md-option>
                    </md-select>

                </md-field>

                <md-field>
                    <label>Názov tasku</label>
                    <md-input v-model="type" maxlength="100" style="border-bottom: 1px solid #b0adad;" required></md-input>
                    <span class="md-helper-text">Krátky popis max 100 znakov. Názov tasku má byť jasný.</span>
                </md-field>
                <md-field style="margin-top: 60px">
                    <label>Širší popis tasku</label>
                    <md-textarea v-model="description" maxlength="1200" required></md-textarea>
                    <md-icon>description</md-icon>
                </md-field>

                <VueFileAgent
                        ref="vueFileAgent"
                        :theme="'list'"
                        :multiple="true"
                        :deletable="true"
                        :meta="true"
                        :accept="'image/*'"
                        :maxSize="'10MB'"
                        :maxFiles="14"
                        :helpText="'Pridajte nám prílohu - len IMG (jpg, png, jpeg, gif).'"
                        :errorText="{
      type: 'Chybná prípona súboru. Len IMG (jpg, png, jpeg, gif)',
      size: 'Maximňalne 10MB sňubor',
    }"
                        :thumbnailSize="1200"
                        @select="filesSelected($event)"
                        @beforedelete="onBeforeDelete($event)"
                        @delete="fileDeleted($event)"
                        v-model="fileRecords"
                ></VueFileAgent>


                <md-button class="md-raised md-accent md-primary"  @click="uploadFiles()" style="margin-top: 20px"><i class="fa fa-paper-plane-o" aria-hidden="true"></i> Pridaj Task</md-button>
            </div>
        </md-drawer>

        <div id="dash">

            <h2><i class="fa fa-tasks"></i> Tasky</h2>
            <md-card style="box-shadow: none"><md-progress-bar md-mode="indeterminate" v-if="progresak"></md-progress-bar>
            <md-table v-model="strankySu" md-sort="nazov" md-sort-order="asc">

                <md-table-toolbar style="display:block">
                    <div  style="max-width: 300px;float: left;">
                        <md-field>
                            <md-input placeholder="Hľadaj ..." v-model="search" @input="searchOnTable" />
                        </md-field>
                    </div>

                    <div style="max-width: 300px;float: right;">

                        <md-button class="md-icon-button md-raised md-primary" style="float: right;margin: 5px;position:relative" @click="showSidepanel = true" ><md-tooltip md-direction="top">Nový task</md-tooltip>
                            <i class="fa fa-plus" style="margin-top: 2px;"></i>
                        </md-button>
                    </div>

                </md-table-toolbar>
                <md-table-row slot="md-table-row" slot-scope="{ item }"  class="sklo"     v-bind:class="item.ukoncne == 1?'green':'red'" >

                    <md-table-cell md-label="Druh tasku" md-sort-by="druh">
                      <span v-if="item.druh == 1" style="float: left; margin-top: 3px;"><i class="fa fa-comments-o" aria-hidden="true" style="width: 25px;"></i></span>
                      <span v-if="item.druh == 2"><i class="fa fa-bug" aria-hidden="true" style="width: 25px;color: #fa8a8a"></i></span>
                      <span v-if="item.druh == 0" ><i class="fa fa-clock-o" aria-hidden="true" style="width: 25px;color: #8bc34a"></i></span>
                      <md-field class="prioclass" v-if="item.druh == 1" style="margin-bottom: 0px" >
                        <md-select   name="prinn" @md-selected="zmenDruh(item.id,$event)"
                                     dispabled="true" >
                          <md-option value="1"><i class="fa fa-comments-o" aria-hidden="true" style="width: 25px;"></i> Hlásenie požiadavky - Riešenie podľa poradia. 40€ bez DPH / Hod</md-option>
                          <md-option value="2" style="display: none"><i class="fa fa-bug" aria-hidden="true" style="width: 25px;color: #fa8a8a"></i> ASAP - Hlásenie poruchy / Error. Riešenie do 48h. 40€ bez DPH / Hod</md-option>
                          <md-option value="0"><i class="fa fa-clock-o" aria-hidden="true" style="width: 25px;color: #8bc34a"></i> ASAP - Hlásenie požiadavky. Riešenie do 48h. 60€ bez DPH / Hod</md-option>
                        </md-select>
                      </md-field>
                    </md-table-cell>
                    <md-table-cell md-label="Názov" md-sort-by="nazov"><span class="noaz" @click="lockTask(item.id)"><i class="fa fa-tag" aria-hidden="true"></i> {{item.nazov}}</span></md-table-cell>
                    <md-table-cell md-label="Priorita" md-sort-by="priorita">
                        <span v-if="item.priorita == 1" class="prve"></span>
                        <span v-if="item.priorita == 2" class="druhe"></span>
                        <span v-if="item.priorita == 3" class="tretie"></span>

                        <md-field class="prioclass" style="margin-bottom: 0px">
                            <md-select   name="prinn" @md-selected="zmenPrioritu(item.id,$event)"
                                         dispabled="true" >
                                <md-option value="1" style="padding-left: 10px;"><span class="prve"></span>Nízska</md-option>
                                <md-option value="2" style="padding-left: 10px;"><span class="druhe"></span>Stedná</md-option>
                                <md-option value="3" style="padding-left: 10px;"><span class="tretie"></span>Vysoká</md-option>
                            </md-select>
                        </md-field>

                    </md-table-cell>
                    <md-table-cell md-label="Vytvorené" md-sort-by="created_at">{{ item.kedy}}</md-table-cell>
                    <md-table-cell md-label="Čas" md-sort-by="cas_at">{{ item.cas}}</md-table-cell>
                    <md-table-cell md-label="Komentárov" md-sort-by="komentare">{{item.komentare}} <i class="fa fa-comment-o" aria-hidden="true"></i></md-table-cell>
                    <md-table-cell md-label="-" md-sort-by="zvoncek"> <div class="ringing-bell"> <i v-bind:class="item.zvoncek == 1?'fa fa-bell faa-ring animated fa-5x':'nic'"></i>
                    </div></md-table-cell>
                </md-table-row>
            </md-table>

            </md-card>
        </div>
      <TermsModal/>
        </div>

</template>

<script>
    import store from "../store";
    import TermsModal from "@/components/TermsModal.vue";
    export default {
        name: "TaskComponent.vue",
      components: {
        TermsModal
      },
        data() {
            return {
                authenticated:this.authenticated,
                search: null,
                defaultSearch:[],
                description:'',
                type:'',
                idkoje:'',
                nazovTasku:'',
                popisTasku:'',
                druh:1,
                poz:1,
                prinn:1,
                por:0,
                strankySu:[],
                progresak:true,
                showSidepanel:false,
                fileRecords: [],
                uploadUrl: 'https://sla.giru.sk/inc/AddTask.php',
                uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
                fileRecordsForUpload: [],
                notificationSystem: {
                    options: {
                        warning: {
                            position: "topRight"
                        },
                        error: {
                            position: "topRight"
                        },
                        success: {
                            position: "topRight"
                        }
                    }
                },

            }

        },
        methods:{

            setAuthenticated(status) {
                this.authenticated = status;

            },
            lockTask(id){
                this.$router.push({path:'/task/'+id})

            },

            searchOnTable () {
                 this.strankySu = searchByName(this.defaultSearch, this.search);

            },
            cochce(){
                console.log('menosadruh');
                if(this.druh == 1){
                    this.poz = 1;
                    this.por = 0;
                }else{
                    this.poz = 0;
                    this.por = 1;
                }
            },
            uploadFiles: function () {
               var self = this;
                this.nazovTasku = this.type;
                this.popisTasku = this.description;

                if(this.type != '' && this.description != '') {
                    this.$http.post('https://sla.giru.sk/inc/AddTask.php',  { nazovTasku: this.nazovTasku, popisTasku: this.popisTasku,druhTasku:this.druh,foto:this.fileRecordsForUpload,kto:store.getters.idkojes,email:store.getters.emailje,nazov:store.getters.menosje }).then(function (response) {
                        console.log(response.data);
                        self.type = '';
                        self.description = '';
                        //self.$refs.vueFileAgent.deleteFileRecord();
                        self.showSidepanel = false;
                        self.loadTaskuj();
                    });

                }

            },
            zmenPrioritu(idk,event){
                var self = this;
                this.$http.post('https://sla.giru.sk/inc/UpdTaskPrio.php',  { id:idk,prio:event}).then(function (response) {
                    console.log(response.data);
                    self.loadTaskuj();
                });
            },
          zmenDruh(idk,event){
                var self = this;
                this.$http.post('https://sla.giru.sk/inc/UpdTaskDruh.php',  { id:idk,prio:event}).then(function (response) {
                    console.log(response.data);
                    self.loadTaskuj();
                });
            },

            loadTaskuj(){
                var self = this;
                self.progresak = true;
                this.idkoje = store.getters.idkojes;
                console.log(this.idkoje);
                this.$http.post('https://sla.giru.sk/inc/LoadTask.php',  { id:this.idkoje}).then(function (response) {
                    console.log(response.data);
                    self.strankySu =response.data;
                    self.defaultSearch =response.data;
                    self.progresak = false;
                });
            },

            filesSelected: function (fileRecordsNewlySelected) {
                var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
                this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            },
            onBeforeDelete: function (fileRecord) {
                var i = this.fileRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    this.fileRecordsForUpload.splice(i, 1);
                } else {
                    if (confirm('Naozaj ho chcete vymazať?')) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                }
            },



        },
        beforeCreate() {
         var self = this;
            console.log("beforeCreate  T");
            this.menoje = store.getters.menosje;
            this.idkoje = store.getters.idkojes;

            if(!this.menoje) {
                store.commit('ADD_IDKOS', '');
                store.commit('ADD_MENO', '');
                window.location.href = '/#/';
            }else{
                self.progresak = true;
                this.authenticated = true;
                this.$http.post('https://sla.giru.sk/inc/LoadTask.php',  { id:this.idkoje}).then(function (response) {
                    self.progresak = false;
                    self.strankySu =response.data;
                    self.defaultSearch =response.data;
                });
            }

        },

    }
    const toLower = text => {
        return text.toString().toLowerCase()
    }

    const searchByName = (items, term) => {
        if (term) {
            return items.filter(item => toLower(item.nazov).includes(toLower(term)))
        }

        return items
    }
</script>

<style scoped>
.green{
    background-color: #f5ffe9;
}


    .fa-check{
        color: #009688;
    }
    .fa-cogs{
        color: #9C27B0;
    }
    .noaz{
        cursor: pointer;
    }
    .noaz:hover{
        color: #d91c5e;
    }
.md-card{
    padding-top: 5px !important;
    border-radius: 10px;
    overflow: hidden;
}
.md-progress-bar {
    position: absolute;
    top: 0px;
    height: 5px;
    width: 100%;
}
.md-menu-content.md-select-menu{
    z-index: 11 !important;
}
    .md-drawer{
        z-index: 12 !important;
    }
body {
    background-color: #eee
}

.prve:before,.druhe:before,.tretie:before{
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    float: left;
    position: absolute;
    top: 20px;
    left: 2px;
}
.prve:before{
    background-color: green;
}
.druhe:before{
    background-color: #ff9347;
}
.tretie:before{
    background-color: #d80012;
}
.prioclass{
    min-height: auto;
    padding: 0;
    width: 24px;
  float: left;
  margin-top: -5px;
  margin-left: -11px;
}

.prioclass .md-icon-image svg{
    display: none !important;
}
.prioclass:after{
    height: 0px !important;
}
.prioclass .md-select-icon {
    display: none;
}

.prioclass md-option:after{
    content: 'j';
}
input::-webkit-input-placeholder { /* Chrome, Safari, Opera */
  color: #d91e5e !important;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #d91e5e !important;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: #d91e5e !important;
}

input::placeholder { /* Štandardný zápis */
  color: #d91e5e !important;
}
.ringing-bell {

    transition: translate(-50%, -50%)
}

.faa-ring {
    font-size: 17px;
    color: #d91c5e;
}

#TaskComponent .md-toolbar.md-theme-default.md-transparent {
  background-color: transparent !important;
  background: none !important;
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg)
    }

    2% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg)
    }

    4% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    6% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    8% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg)
    }

    10% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg)
    }

    12% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    14% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    16% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg)
    }

    18% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg)
    }

    20% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

@keyframes ring {
    0% {
        -webkit-transform: rotate(-15deg);
        -ms-transform: rotate(-15deg);
        transform: rotate(-15deg)
    }

    2% {
        -webkit-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        transform: rotate(15deg)
    }

    4% {
        -webkit-transform: rotate(-18deg);
        -ms-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    6% {
        -webkit-transform: rotate(18deg);
        -ms-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    8% {
        -webkit-transform: rotate(-22deg);
        -ms-transform: rotate(-22deg);
        transform: rotate(-22deg)
    }

    10% {
        -webkit-transform: rotate(22deg);
        -ms-transform: rotate(22deg);
        transform: rotate(22deg)
    }

    12% {
        -webkit-transform: rotate(-18deg);
        -ms-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    14% {
        -webkit-transform: rotate(18deg);
        -ms-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    16% {
        -webkit-transform: rotate(-12deg);
        -ms-transform: rotate(-12deg);
        transform: rotate(-12deg)
    }

    18% {
        -webkit-transform: rotate(12deg);
        -ms-transform: rotate(12deg);
        transform: rotate(12deg)
    }

    20% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

.faa-ring.animated,
.faa-ring.animated-hover:hover,
.faa-parent.animated-hover:hover>.faa-ring {
    -webkit-animation: ring 2s ease infinite;
    animation: ring 2s ease infinite;
    transform-origin-x: 50%;
    transform-origin-y: 0px;
    transform-origin-z: initial
}
h2 .fa{
  color: #ee487d;
}
</style>