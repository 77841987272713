<template>
  <div id="LoginComponent">
    <img src="~@/assets/img/logo.svg" alt="GIRU" class="logo_giru"/>
    <RotatingDiv class="logiokno">

      <form>
        <md-field>
          <label>Prihlasovacie meno</label>
          <md-input v-model="name" name="name" required @keyup.enter="login()"></md-input>
        </md-field>
        <md-field>
          <label>Heslo</label>
          <md-input v-model="password" type="password" name="password" required></md-input>
        </md-field>
        <md-button class="md-raised md-primary bau" v-on:click="login()"><i class="fas fa-sign-in-alt"></i> Prihlásiť sa <i class="fa fa-sign-in" aria-hidden="true"></i>
        </md-button>

      </form>
    </RotatingDiv>
    <notifications group="err" position="top right" width="400"/>
  </div>

</template>

<script>
import store from "../store";
import RotatingDiv from "@/components/RotatingDiv.vue";

export default {
  name: "LoginComponent",
  components: {
    RotatingDiv
  },
  data() {
    return {
      name: "",
      password: ""
    }

  },
  methods: {
    login() {
      var self = this;
      var meno = this.name;
      var heslo = this.password;

      if (meno == '' || heslo == '') {
        this.$notify({
          group: 'err',
          type: 'error',
          title: '<i class="fas fa-exclamation-triangle"></i> Chyba',
          text: 'Meno a heslo sú povinné',
          duration: 1000,
          speed: 1000
        });
      } else {
        //
        this.$http.post('https://sla.giru.sk/inc/login.php', {
          username: this.name,
          password: this.password
        }).then(function (response) {
          console.log(response.data);
          if (response.data == 'nie') {
            self.$notify({
              group: 'err',
              type: 'error',
              title: 'Chyba',
              text: 'Meno a heslo sú nesprávne',
              duration: 1000,
              speed: 1000
            });
          } else {
            if (response.data['id']) {
              store.commit('ADD_IDKOS', response.data['id']);
              store.commit('ADD_MENO', response.data['firma']);
              store.commit('ADD_EMAIL', response.data['email']);
              self.$emit("authenticated", true);
              self.$emit("menoje", response.data['firma']);
              self.$router.push({name: 'dashboard'})

            } else {
              self.$notify({
                group: 'err',
                type: 'error',
                title: 'Chyba',
                text: 'Kontaktujte admina',
                duration: 1000,
                speed: 1000
              });
              console.log(response.data)
            }
          }

        });
      }

    },


  }
}
</script>


<style scoped lang="scss">


#LoginComponent {
  //background-image: url('~@/assets/img/lock.jpg');
  //background-repeat: no-repeat;
  //background-position: center;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, #212a3b, #111621);
}

.md-field.md-theme-default.md-focused .md-input, .md-field.md-theme-default.md-focused .md-textarea, .md-field.md-theme-default.md-has-value .md-input, .md-field.md-theme-default.md-has-value .md-textarea {
  -webkit-text-fill-color: rgb(255 255 255 / 87%);
}
.md-field.md-theme-default label {
  color: rgb(255 255 255);
}
.md-field.md-theme-default:after {
  background-color: rgb(255 255 255 / 42%);
}
.md-icon.md-theme-default.md-icon-image svg{
  fill: rgba(255, 255, 255, 0.54) !important;
  fill: rgb(255 255 255) !important;
}
.md-field.md-theme-default:before {
  background-color: #d91d5e;
  background-color: #d91d5e;
}
input:-webkit-autofill {
  background-color: rgba(255, 255, 255, 0.8) !important; /* Nastav farbu pozadia */
  color: #d91e5e !important; /* Nastav farbu textu */

  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset !important; /* Obíde defaultné pozadie autofill */
  transition: background-color 5000s ease-in-out 0s;
}
.md-field.md-theme-default .md-input:focus {
  border-bottom-color: #d91e5e; /* Nastaví farbu podčiarknutia na tvoju požadovanú */
  box-shadow: 0 1px 0 0 #d91e5e; /* Nastaví tieň na rovnakú farbu */
}
.md-theme-default .md-field.md-input-focused .md-input {
  border-bottom-color: #d91e5e !important;
  box-shadow: 0 2px 0 0 #d91e5e !important;
}
//#LoginComponent:before {
//    background: rgba(0, 0, 0, 0.5);
//    position: absolute;
//    width: 100%;
//    height: 100%;
//    display: block;
//    left: 0;
//    top: 0;
//    content: "";
//}

.logo_giru {
  margin-top: 100px !important;
}

.logiokno {
  position: relative;
  width: 95%;
  max-width: 400px;
  margin: 30px auto;
  background-color: rgba(255, 255, 255, 0.2); /* Priehľadná biela pre efekt skla */
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(10px); /* Efekt rozostrenia */
  -webkit-backdrop-filter: blur(10px); /* Podpora pre Safari */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Jemný tieň pre hĺbku */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Jemný biely obrys */


  img {
    width: 100px;
    position: absolute;
    top: -66px;
    right: 50%;
    margin-right: -50px;
  }

  .bau {
    background-color: #d91e5e !important;
    width: 100%;
    margin-left: 0px;
    height: 52px;
    font-size: 14px;
    letter-spacing: 2px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 600px) {
  .logiokno {
    margin-top: 100px;
  }
}


</style>