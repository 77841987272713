import Vue from "vue";
import App from "./components/App.vue";
import router from "./router";
import store from "./store";

import Notifications from 'vue-notification';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuex from 'vuex';

import VueFileAgent from 'vue-file-agent';
//import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import VueSimpleAlert from "vue-simple-alert";
import VueHtmlToPaper from "vue-html-to-paper";

Vue.use(VueSimpleAlert);
Vue.use(VueFileAgent);
//Vue.use(VueFileAgentStyles);
Vue.use(Vuex);

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://sla.becrea.sk/css/print.css',

  ]
}

Vue.use(VueHtmlToPaper,options);


Vue.use(Notifications);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;
new Vue({
  router,
    store,
  render: h => h(App)
}).$mount("#app");







