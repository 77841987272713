<template>
  <div
      class="tilting-div"
      :style="{ transform: `perspective(1000px) rotateY(${tilt}deg)` }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tilt: 0,
      maxTilt: 10, // Max. úhel náklonu doleva
      minTilt: -10 // Max. úhel náklonu doprava
    };
  },
  mounted() {
    // Naslouchání události mousemove na celém dokumentu
    document.addEventListener("mousemove", this.tiltDiv);
  },
  beforeDestroy() {
    // Odstranění naslouchání události mousemove při zničení komponenty
    document.removeEventListener("mousemove", this.tiltDiv);
  },
  methods: {
    tiltDiv(event) {
      const rect = this.$el.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const distance = event.clientX - centerX;
      let tilt = (distance / rect.width) * this.maxTilt;

      // Omezíme úhel náklonu na hodnoty mezi minTilt a maxTilt
      tilt = Math.max(this.minTilt, Math.min(tilt, this.maxTilt));

      this.tilt = tilt;
    }
  }
};
</script>

<style scoped>
.tilting-div {
  transition: transform 1s ease;
}
</style>