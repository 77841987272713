<template>
    <div id="TaskComponent"   v-if="authenticated">

        <div id="dash">

            <h2><i class="fa fa-history"></i> História</h2>
            <md-card style="border: 0px; box-shadow: none"><md-progress-bar md-mode="indeterminate" v-if="progresak"></md-progress-bar>
                <md-table v-model="strankySu" md-sort="nazov" md-sort-order="asc">

                    <md-table-toolbar style="display:block">
                        <RotatingDiv  style="max-width: 300px;float: left;">
                            <md-field>
                                <md-input placeholder="Hľadaj ..." v-model="search" @input="searchOnTable" />
                            </md-field>
                        </RotatingDiv>



                    </md-table-toolbar>
                    <md-table-row slot="md-table-row" slot-scope="{ item }"  class="sklo backsklo"     v-bind:class="item.ukoncne == 1?'green':'red'" >

                        <md-table-cell md-label="Názov" md-sort-by="nazov"><span class="noaz" @click="lockTask(item.id)"><i class="fa fa-tag" aria-hidden="true"></i> {{item.nazov}}</span></md-table-cell>
                                              <md-table-cell md-label="Vytvorené" md-sort-by="created_at">{{ item.kedy}}</md-table-cell>
                        <md-table-cell md-label="Ukončené" md-sort-by="ukoncne"><i v-bind:class="item.ukoncne == 1?'fa fa-check':'fa fa-cogs'" aria-hidden="true"></i></md-table-cell>
                        <md-table-cell md-label="Komentárov" md-sort-by="komentare">{{item.komentare}} <i class="fa fa-comment-o" aria-hidden="true"></i></md-table-cell>

                    </md-table-row>
                </md-table>

            </md-card>
        </div>
    <TermsModal />
</div>
</template>

<script>
    import store from "../store";
    import RotatingDiv from "@/components/RotatingDiv.vue";
    import TermsModal from "@/components/TermsModal.vue";
    export default {
        name: "HistoriaComponent.vue",
      components: {
        RotatingDiv,
        TermsModal
      },

    data() {
        return {
            authenticated:this.authenticated,
            search: null,
            defaultSearch:[],
            description:'',
            type:'',
            idkoje:'',
            nazovTasku:'',
            popisTasku:'',
            druh:1,
            poz:1,
            prinn:1,
            por:0,
            strankySu:[],
            progresak:true,
            showSidepanel:false,




        }

    },
    methods:{

        setAuthenticated(status) {
            this.authenticated = status;
        },
        lockTask(id){
            this.$router.push({path:'/task/'+id})

        },

        searchOnTable () {
            this.strankySu = searchByName(this.defaultSearch, this.search);

        },
        cochce(){
            console.log('menosadruh');
            if(this.druh == 1){
                this.poz = 1;
                this.por = 0;
            }else{
                this.poz = 0;
                this.por = 1;
            }
        },



        loadTaskuj(){
            var self = this;
            self.progresak = true;
            this.idkoje = store.getters.idkojes;
            console.log(this.idkoje);
            this.$http.post('//sla.becrea.sk/inc/LoadTaskHistory.php',  { id:this.idkoje}).then(function (response) {
                console.log(response.data);
                self.strankySu =response.data;
                self.defaultSearch =response.data;
                self.progresak = false;
            });
        },




    },
    beforeCreate() {
        var self = this;
        console.log("beforeCreate  T");
        this.menoje = store.getters.menosje;
        this.idkoje = store.getters.idkojes;

        if(!this.menoje) {
            store.commit('ADD_IDKOS', '');
            store.commit('ADD_MENO', '');
            window.location.href = '/#/';
        }else{
            self.progresak = true;
            this.authenticated = true;
            this.$http.post('https://sla.giru.sk/inc/LoadTaskHistory.php',  { id:this.idkoje}).then(function (response) {
                self.progresak = false;
                self.strankySu =response.data;
                self.defaultSearch =response.data;
            });
        }

    },

    }
    const toLower = text => {
        return text.toString().toLowerCase()
    }

    const searchByName = (items, term) => {
        if (term) {
            return items.filter(item => toLower(item.nazov).includes(toLower(term)))
        }

        return items
    }
</script>

<style scoped>
    .green{
        background-color: #f5ffe9;
    }
    .red{
        background-color: #f5def9;
    }

    .fa-check{
        color: #009688;
    }
    .fa-cogs{
        color: #9C27B0;
    }
    .noaz{
        cursor: pointer;
    }
    .noaz:hover{
        color: #d91d5e;
    }
    .md-card{
        padding-top: 5px !important;
        border-radius: 10px;
        overflow: hidden;
    }
    .md-progress-bar {
        position: absolute;
        top: 0px;
        height: 5px;
        width: 100%;
    }
    .md-menu-content.md-select-menu{
        z-index: 11 !important;
    }
    .md-drawer{
        z-index: 12 !important;
    }
    body {
        background-color: #eee
    }

    .prve:before,.druhe:before,.tretie:before{
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        float: left;
        position: absolute;
        top: 17px;
        left: 2px;
    }
    .prve:before{
        background-color: green;
    }
    .druhe:before{
        background-color: #ff9347;
    }
    .tretie:before{
        background-color: #d80012;
    }
    .prioclass{
        min-height: auto;
        padding: 0;
        margin: 0;
        width: 24px;
    }

    .prioclass .md-icon-image svg{
        display: none !important;
    }
    .prioclass:after{
        height: 0px !important;
    }
    .prioclass .md-select-icon {
        display: none;
    }

    .prioclass md-option:after{
        content: 'j';
    }

    .ringing-bell {

        transition: translate(-50%, -50%)
    }

    .faa-ring {
        font-size: 17px;
        color: #ee487d;
    }
    input::-webkit-input-placeholder { /* Chrome, Safari, Opera */
      color: #d91e5e !important;
    }

    input:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #d91e5e !important;
    }

    input::-ms-input-placeholder { /* Microsoft Edge */
      color: #d91e5e !important;
    }

    input::placeholder { /* Štandardný zápis */
      color: #d91e5e !important;
    }
    @-webkit-keyframes ring {
        0% {
            -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg)
        }

        2% {
            -webkit-transform: rotate(15deg);
            transform: rotate(15deg)
        }

        4% {
            -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg)
        }

        6% {
            -webkit-transform: rotate(18deg);
            transform: rotate(18deg)
        }

        8% {
            -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg)
        }

        10% {
            -webkit-transform: rotate(22deg);
            transform: rotate(22deg)
        }

        12% {
            -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg)
        }

        14% {
            -webkit-transform: rotate(18deg);
            transform: rotate(18deg)
        }

        16% {
            -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg)
        }

        18% {
            -webkit-transform: rotate(12deg);
            transform: rotate(12deg)
        }

        20% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
        }
    }

    @keyframes ring {
        0% {
            -webkit-transform: rotate(-15deg);
            -ms-transform: rotate(-15deg);
            transform: rotate(-15deg)
        }

        2% {
            -webkit-transform: rotate(15deg);
            -ms-transform: rotate(15deg);
            transform: rotate(15deg)
        }

        4% {
            -webkit-transform: rotate(-18deg);
            -ms-transform: rotate(-18deg);
            transform: rotate(-18deg)
        }

        6% {
            -webkit-transform: rotate(18deg);
            -ms-transform: rotate(18deg);
            transform: rotate(18deg)
        }

        8% {
            -webkit-transform: rotate(-22deg);
            -ms-transform: rotate(-22deg);
            transform: rotate(-22deg)
        }

        10% {
            -webkit-transform: rotate(22deg);
            -ms-transform: rotate(22deg);
            transform: rotate(22deg)
        }

        12% {
            -webkit-transform: rotate(-18deg);
            -ms-transform: rotate(-18deg);
            transform: rotate(-18deg)
        }

        14% {
            -webkit-transform: rotate(18deg);
            -ms-transform: rotate(18deg);
            transform: rotate(18deg)
        }

        16% {
            -webkit-transform: rotate(-12deg);
            -ms-transform: rotate(-12deg);
            transform: rotate(-12deg)
        }

        18% {
            -webkit-transform: rotate(12deg);
            -ms-transform: rotate(12deg);
            transform: rotate(12deg)
        }

        20% {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg)
        }
    }

    .faa-ring.animated,
    .faa-ring.animated-hover:hover,
    .faa-parent.animated-hover:hover>.faa-ring {
        -webkit-animation: ring 2s ease infinite;
        animation: ring 2s ease infinite;
        transform-origin-x: 50%;
        transform-origin-y: 0px;
        transform-origin-z: initial
    }
    h2 .fa{
      color: #ee487d;
    }
</style>