<template>
    <div id="NastaveniaComponent"   v-if="authenticated">
        <div id="dash">
            <h2><i class="fa fa-cogs"></i> Notifikácie</h2>
            <md-card class="sklo backsklo "><md-progress-bar md-mode="indeterminate" v-if="progresak"></md-progress-bar>

            <md-tabs >
                <md-tab id="tab-pages-1" md-label="Email">
                    <span style="padding: 15px;display: block;position: relative;"><strong>Notifikácie emailom</strong><br>
                <span class="info"><i class="fa fa-info" aria-hidden="true"></i> Notifikácie slúžia na Vaše informovanie o komentovaní Taskov.<br><i class="fa fa-info" aria-hidden="true"></i> Pokiaľ nemáte nastavený email, notifikácie chodia na Váš prihlasovací email. </span>
                    <div style="clear:both;"></div>
                    <md-checkbox v-model="notifikacie">Mám záujem </md-checkbox>
                        <md-field>
                    <label>Notifikačný email</label>
                    <md-input v-model="notemail" maxlength="100" style="border-bottom: 1px solid #b0adad;" required></md-input>
                    <span class="md-helper-text">Pokiaľ je taký, ako Váš prihlasovacií email. Nechajte toto pole prázdne.</span></md-field>
                             <md-button class="md-icon-button md-raised md-primary ulozNotnas"  @click="ulozNotnas()" ><md-tooltip md-direction="top">Ulož nastavenia</md-tooltip>
                            <i class="fa fa-save" style="margin-top: 2px;"></i>
                        </md-button>
                </span>

                </md-tab>
                <md-tab id="tab-pages-2" md-label="Viber">
                       <span style="padding: 15px;display: block;position: relative;"><strong>Notifikácie na Viber</strong><br>
                <span class="info"><i class="fa fa-info" aria-hidden="true"></i> Notifikácie slúžia na Vaše informovanie o komentovaní Taskov.<br><i class="fa fa-info" aria-hidden="true"></i> Pre zasielanie notifikácií na Viber je potrebné definovať vaše ID.<br><md-button class="md-primary md-raised" @click="showDialog = true">Ako na to?</md-button></span>
                    <div style="clear:both;"></div>
                    <md-checkbox v-model="notifikacieViber">Mám záujem </md-checkbox>
                        <md-field>
                    <label>Viber ID</label>
                    <md-input v-model="notemailViber" maxlength="100" style="border-bottom: 1px solid #b0adad;" required></md-input>
                    </md-field>
                             <md-button class="md-icon-button md-raised md-primary ulozNotnas"  @click="ulozNotnasViber()" ><md-tooltip md-direction="top">Ulož nastavenia</md-tooltip>
                            <i class="fa fa-save" style="margin-top: 2px;"></i>
                        </md-button>
                </span>
                </md-tab>
            </md-tabs>

            </md-card>
        </div>

    <md-dialog :md-active.sync="showDialog">
        <md-dialog-title style="background-color: #d91d5e;color: white;padding-bottom: 10px;padding-top: 10px;">Nastavení Notifikácií na Viber</md-dialog-title>

      <div style="padding: 15px">
          <div class="md-layout">
              <div class="md-layout-item">
                  Prihláste sa na Viber a oskenujte si tento QR kod. Vďaka nemu nájdete našu firmu na jednu šupu.<br><br>
                  1. Zapnite si Viber, Dostante sa do profilu a v pravom hornom rohu máte čítačvku QR. Načítajte si tento QR kód.<br>
              <img src="https://sla.giru.sk/img/download.png" style="width: 150px; margin: 15px"><br><br>
                  2. Ukáže sa Vám náš profil. Stlačte správu.<br><br>
                  3. Robot Vás navedie. Nezabudnite Som SLA partner.<br><br>
                  4. Kliknite na link, zadajte svoj emal, ktorým sa prihlasujete do SLA konta.<br><br>
                  5. Máte to!
              </div>
              <div class="md-layout-item" style="text-align: center">
          <video width="250" height="440" controls>
              <source src="https://sla.giru.sk/viber.mp4" type="video/mp4">
              Your browser does not support the video tag.
          </video>
              </div>
          </div>
      </div>
    </md-dialog>
      <TermsModal />
    </div>
</template>

<script>
    import store from "../store";
    import TermsModal from "@/components/TermsModal.vue";
    export default {
        name: "NastaveniaComponent",
      components: {
        TermsModal
      },
        data() {
            return {
                authenticated:this.authenticated,
                progresak:true,
                showDialog: false,
                notifikacie:true,
                notifikacieViber:true,
                notemail:'',
                notemailViber:'',
                notificationSystem: {
                    options: {
                        warning: {
                            position: "topRight"
                        },
                        error: {
                            position: "topRight"
                        },
                        success: {
                            position: "topRight"
                        }
                    }
                },
            }
        },
        methods:{
            setAuthenticated(status) {
                this.authenticated = status;

            },
            ulozNotnasViber(){

                var self = this;
                this.idkoje = store.getters.idkojes;
                this.$http.post('https://sla.giru.sk/inc/UpdNotifikacieViber.php',  {id: this.idkoje,notifikacie:this.notifikacieViber,notemail:this.notemailViber}).then(function (response) {
                    console.log(response.data);
                    if(response.data == 'email'){
                        self.$fire({
                            title: "Neuložené!",
                            text: "Email nemá správny tvar",
                            type: "error",
                            timer: 3000
                        })
                    }else{
                        self.$fire({
                            title: "Uložené!",
                            text: "Vaše nastavenia boli uložené.",
                            type: "success",
                            timer: 3000
                        })
                    }

                });

            },
            ulozNotnas(){
                var self = this;
                this.idkoje = store.getters.idkojes;
                this.$http.post('https://sla.giru.sk/inc/UpdNotifikacie.php',  {id: this.idkoje,notifikacie:this.notifikacie,notemail:this.notemail}).then(function (response) {
                    console.log(response.data);
                    if(response.data == 'email'){
                        self.$fire({
                            title: "Neuložené!",
                            text: "Email nemá správny tvar",
                            type: "error",
                            timer: 3000
                        })
                    }else{
                        self.$fire({
                            title: "Uložené!",
                            text: "Vaše nastavenia boli uložené.",
                            type: "success",
                            timer: 3000
                        })
                    }

                });
            }

        },


        beforeCreate() {
            var self = this;
            console.log("beforeCreate  N");
            this.menoje = store.getters.menosje;
            this.idkoje = store.getters.idkojes;

            if(!this.menoje) {
                store.commit('ADD_IDKOS', '');
                store.commit('ADD_MENO', '');
                window.location.href = '/#/';
            }else{
                self.progresak = true;
                this.authenticated = true;
                this.$http.post('https://sla.giru.sk/inc/LoadNotifikacie.php',  { id:this.idkoje}).then(function (response) {
                    if(response.data == 'nema'){
                        self.progresak = false;

                    }else{
                        self.progresak = false;
                        if(response.data['notifikacie'] == 0){
                            self.notifikacie = false
                        }else{
                            self.notifikacie = true
                        }
                        self.notemail = response.data['notemail'];

                        if(response.data['notviber'] == 0){
                            self.notifikacieViber = false
                        }else{
                            self.notifikacieViber = true
                        }
                        self.notemailViber = response.data['viberid'];
                        console.log(response.data)
                    }
                });
            }

        },
    }
</script>

<style scoped>
    .green{
        background-color: #f5ffe9;
    }
    .red{
        background-color: #f5def9;
    }

    .fa-check{
        color: #009688;
    }
    .fa-cogs{
        color: #ee487d;
    }
    .noaz{
        cursor: pointer;
    }
    .noaz:hover{
        color: darkred;
    }
    .md-card{
        padding-top: 5px !important;
        border-radius: 10px;
        overflow: hidden;
    }
    .md-progress-bar {
        position: absolute;
        top: 0px;
        height: 5px;
        width: 100%;
    }
    .info{
        color: #ffffff;
        font-size: 12px;
    }
    .ulozNotnas{
        position: absolute;
        top: 15px;
        right: 15px;
    }
    h2 .fa{
      color: #ee487d;
    }
</style>