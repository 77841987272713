import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        iid: '',
        mmeno: '',
        eemail: '',

    },
    getters: {
        idkojes: state => state.iid,
        menosje: state => state.mmeno,
        emailje: state => state.eemail,

    },
    mutations: {
        ADD_IDKOS:(state,id) => {
            state.iid = id
        },
        ADD_MENO:(state,meno) => {
            state.mmeno = meno
        },
        ADD_EMAIL:(state,meno) => {
            state.eemail = meno
        },

    },
    actions: {

    }
});
