import Vue from 'vue';
import VueRouter from 'vue-router';


import LoginComponent from "./components/LoginComponent.vue";
import NastaveniaComponent from "./components/NastaveniaComponent.vue";
import DashboardComponent from "./components/DashComponent.vue";
import TaskComponent from "./components/TaskComponent.vue";
import ReportComponent from "./components/ReportComponent.vue";
import TaskIdComponent from "./components/TaskIdComponent.vue";
import HistoriaComponent from "./components/HistoriaComponent.vue";
import CennikComponent from "./components/CennikComponent.vue";
import GdprComponent from "./components/GdprComponent.vue";
import ObchodneCmponent from "./components/ObchodneCmponent.vue";
import ReklamaciaComponent from "./components/ReklamaciaComponent.vue";


Vue.use(VueRouter);


export default new VueRouter({
    routes: [
        { path: '/', name: "login", component: LoginComponent},
        { path: '/dashboard',   name: "dashboard", component: DashboardComponent},
        { path: '/nastavenia',   name: "nastavenia", component: NastaveniaComponent},
        { path: '/gdpr',   name: "gdpr", component: GdprComponent},
        { path: '/obchodne',   name: "obchodne", component: ObchodneCmponent},
        { path: '/reklamacia',   name: "reklamacia", component: ReklamaciaComponent},
        { path: '/report',   name: "report", component: ReportComponent},
        { path: '/historia',   name: "historia", component: HistoriaComponent},
        { path: '/cennik',   name: "cennik", component: CennikComponent},
        { path: '/task',   name: "task", component: TaskComponent},
        { path: '/task/:id',   name: "taskid", component: TaskIdComponent},


    ],
    mode: 'history' //for newer browsers
})